import React from "react";
import Slider from "react-slick";
import "slick-carousel/slick/slick-theme.css";
import "slick-carousel/slick/slick.css";
import "./Testimonial.css";

function Testimonial() {
  const testimonials = [
    {
      id: 1,
      name: "Sarah Khan",
      position: "CEO, Techshape Solutions",
      text: "Some quick example text to build on the card title and make up the bulk of the card's content.",
      image: "https://randomuser.me/api/portraits/women/1.jpg",
    },
    {
      id: 2,
      name: "Ali Ahmed",
      position: "CTO, Innovate Hub",
      text: "Lorem ipsum dolor sit amet, consectetur adipiscing elit. Sed do eiusmod tempor incididunt ut labore et dolore magna aliqua.",
      image: "https://randomuser.me/api/portraits/men/2.jpg",
    },
    {
      id: 3,
      name: "Ayesha Khan",
      position: "Founder, Creative Minds",
      text: "Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat.",
      image: "https://randomuser.me/api/portraits/women/2.jpg",
    },
    {
      id: 4,
      name: "John Doe",
      position: "Manager, BuildTech Co.",
      text: "Duis aute irure dolor in reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla pariatur.",
      image: "https://randomuser.me/api/portraits/men/3.jpg",
    },
  ];

  const settings = {
    dots: true,
    infinite: true,
    speed: 500,
    slidesToShow: 2,
    slidesToScroll: 1,
    autoplay: true,
    autoplaySpeed: 3000,
    pauseOnHover: true,
  };

  return (
    <div className="container-fluid">
      <h1 className="text-center mb-4 fw-bold" style={{ color: "#6f00ff" }}>
        TESTIMONIALS
      </h1>
      <Slider {...settings}>
        {testimonials.map((testimonial) => (
          <div key={testimonial.id} className="p-3">
            <div
              className="card extra-effect"
              style={{
                height: "300px", // Fixed height for consistent card size
                width: "100%",
                borderRadius: "14px",
                border: "2px solid #6f00ff",
                display: "flex",
                flexDirection: "column",
                justifyContent: "center",
                alignItems: "center",
                textAlign: "center",
              }}>
              <div className="card-body">
                <img
                  src={testimonial.image}
                  className="rounded-circle mb-3"
                  alt={testimonial.name}
                  style={{
                    width: "100px",
                    height: "100px",
                    borderRadius: "50%",
                  }}
                />
                <p style={{ overflow: "hidden", textOverflow: "ellipsis" }}>
                  {testimonial.text}
                </p>
                <h3>{testimonial.name}</h3>
                <p>{testimonial.position}</p>
              </div>
            </div>
          </div>
        ))}
      </Slider>
    </div>
  );
}

export default Testimonial;
