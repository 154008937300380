import React from "react";
import "./Team.css"; // Assuming you are using an external CSS file.

function Team() {
  return (
    <div className="container-fluid team-container">
      <h1 className="text-center mt-4 fw-bold team-title">Our Team</h1>
      <p className="text-center team-description">
        Which is the same as saying through shrinking from toil and perfectly
        simple and easy to distinguish.
      </p>

      <div id="teamCarousel" className="carousel slide" data-bs-ride="carousel">
        <div className="carousel-inner">
          <div className="carousel-item active">
            <div className="team-member-container">
              <div className="team-member text-center">
                <img
                  src="shahzaib.png"
                  className="team-member-image"
                  alt="Muhammad Shahzaib"
                />
                <p>Muhammad Shahzaib (CEO)</p>
              </div>
            </div>
          </div>
          <div className="carousel-item">
            <div className="team-member-container">
              <div className="team-member text-center">
                <img
                  src="/sameer.jpg"
                  className="team-member-image"
                  alt="Muhammad Sameer"
                />
                <p>Muhammad Sameer (CTO)</p>
              </div>
            </div>
          </div>
          <div className="carousel-item">
            <div className="team-member-container">
              <div className="team-member text-center">
                <img
                  src="https://randomuser.me/api/portraits/men/5.jpg"
                  className="team-member-image"
                  alt="Muneeb Islam"
                />
                <p>Muneeb Islam (Senior Frontend Developer)</p>
              </div>
            </div>
          </div>
          <div className="carousel-item">
            <div className="team-member-container">
              <div className="team-member text-center">
                <img
                  src="/sharjeel.jpg"
                  className="team-member-image"
                  alt="Muhammad Sharjeel"
                />
                <p>Muhammad Sharjeel (Junior Backend Developer)</p>
              </div>
            </div>
          </div>
          <div className="carousel-item">
            <div className="team-member-container">
              <div className="team-member text-center">
                <img
                  src="https://randomuser.me/api/portraits/women/11.jpg"
                  className="team-member-image"
                  alt="Tayyaba Khaksar"
                />
                <p>Tayyaba Haqsar (Junior Frontend Developer)</p>
              </div>
            </div>
          </div>
          <div className="carousel-item">
            <div className="team-member-container">
              <div className="team-member text-center">
                <img
                  src="https://randomuser.me/api/portraits/women/8.jpg"
                  className="team-member-image"
                  alt="Nayab Shoukat"
                />
                <p>Nayab Shoukat (Junior Frontend Developer)</p>
              </div>
            </div>
          </div>
        </div>

        <button
          className="carousel-control-prev"
          type="button"
          data-bs-target="#teamCarousel"
          data-bs-slide="prev">
          <span
            className="carousel-control-prev-icon"
            aria-hidden="true"></span>
          <span className="visually-hidden">Previous</span>
        </button>
        <button
          className="carousel-control-next"
          type="button"
          data-bs-target="#teamCarousel"
          data-bs-slide="next">
          <span
            className="carousel-control-next-icon"
            aria-hidden="true"></span>
          <span className="visually-hidden">Next</span>
        </button>
      </div>
    </div>
  );
}

export default Team;
