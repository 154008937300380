import React from "react";
import AboutUs from "../AboutUs/AboutUs";
import ContactUs from "../ContactUs/ContactUs";
import Footer from "../Footer/Footer";
import OurClients from "../OurClients/OurClients";
import Services from "../Services/Services";
import Team from "../Team/Team";
import Testimonial from "../Testimonial/Testimonial";
import "./Home.css";

function Home() {
  return (
    <>
      <div class="Home-style">
        <img src="/PitchDeck2.png" className="main-background-image" />

        {/* <video autoPlay loop muted playsInline className="video-background">
        <source src='/back.mp4' type="video/mp4" />
      </video> */}
        {/* <div className="content-overlay">
          <div className="row">
            <div className="col-lg-12 col-md-12 col-sm-12">
              <h1 className="text-center text-dark mt-4 text-white">
                The only way to
                <br />
                build your dream
                <br />
                Software
              </h1>
            </div>
          </div>
        </div> */}
      </div>
      <AboutUs />
      <Services />
      <OurClients />
      <Team />
      <Testimonial />
      <ContactUs />
      <Footer />
    </>
  );
}

export default Home;
