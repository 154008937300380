import React from "react";
import Card from "react-bootstrap/Card";
import Slider from "react-slick";
import "slick-carousel/slick/slick-theme.css";
import "slick-carousel/slick/slick.css";
import "./Services.css";

function Services() {
  const cardData = [
    {
      id: 1,
      title: "Website Development",
      text: "Unlock a wealth of web solutions with our custom web app development services, from SaaS to single-page applications.",
      imageUrl: "/dreamstimefree_22586680.jpg",
    },
    {
      id: 2,
      title: "Mobile App Development",
      text: "Explore our stellar mobile app development services, including native, hybrid, and cross-platform solutions, designed to bring your vision to life.",
      imageUrl: "https://images.unsplash.com/photo-1511707171634-5f897ff02aa9",
    },
    {
      id: 3,
      title: "UI/UX Design",
      text: "Transform your ideas into intuitive and engaging designs with our expert UI/UX services.",
      imageUrl: "https://images.unsplash.com/photo-1498050108023-c5249f4df085",
    },
    {
      id: 4,
      title: "Artificial Intelligence Development",
      text: "Leverage our AI development services to integrate smart solutions into your business processes and drive innovation.",
      imageUrl: "/dreamstimefree_112978356.jpg",
    },
    {
      id: 5,
      title: "Quality Assurance",
      text: "Unlock a wealth of web solutions with our custom web app development services, from SaaS to single-page applications.",
      imageUrl: "/QA.png",
    },
  ];

  const settings = {
    dots: true,
    infinite: true,
    speed: 500,
    slidesToShow: 3,
    slidesToScroll: 1,
    autoplay: true,
    autoplaySpeed: 3000,
    pauseOnHover: true,
  };

  return (
    <div className="container-fluid">
      <h1 className="text-center mb-4 fw-bold" style={{ color: "#6f00ff" }}>
        Our Services
      </h1>
      <Slider {...settings}>
        {cardData.map((card) => (
          <div key={card.id} style={{ padding: "0 10px" }}>
            <Card
              className="further-info"
              style={{
                width: "100%",
                cursor: "pointer",
                borderRadius: "15px",
                height: "400px", // Ensuring consistent card height
              }}>
              <Card.Img
                variant="top"
                src={card.imageUrl}
                style={{
                  height: "250px", // Consistent image height
                  objectFit: "cover",
                  borderRadius: "15px 15px 0 0",
                }}
              />
              <Card.Body
                style={{
                  display: "flex",
                  flexDirection: "column",
                  justifyContent: "space-between",
                }}>
                <Card.Title style={{ fontSize: "18px", fontWeight: "bold" }}>
                  {card.title}
                </Card.Title>
                <Card.Text style={{ fontSize: "14px" }}>{card.text}</Card.Text>
              </Card.Body>
            </Card>
          </div>
        ))}
      </Slider>
    </div>
  );
}

export default Services;
