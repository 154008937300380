import emailjs from "emailjs-com";
import React, { useState } from "react";
import Button from "react-bootstrap/Button";
import Form from "react-bootstrap/Form";

function ContactUs() {
  const [formData, setFormData] = useState({
    name: "",
    email: "",
    service: "",
    message: "",
  });

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData({ ...formData, [name]: value });
  };

  const handleSubmit = (e) => {
    e.preventDefault();

    const serviceId = process.env.REACT_APP_EMAILJS_SERVICE_ID;
    const templateId = process.env.REACT_APP_EMAILJS_TEMPLATE_ID;
    const userId = process.env.REACT_APP_EMAILJS_USER_ID;

    emailjs.send(serviceId, templateId, formData, userId).then(
      (response) => {
        alert("Message sent successfully!");
        console.log("Success:", response);
      },
      (error) => {
        alert("Failed to send message. Please try again.");
        console.error("Error:", error);
      }
    );

    // Reset form
    setFormData({ name: "", email: "", service: "", message: "" });
  };

  return (
    <>
      <div className="container-fluid" style={{ backgroundColor: "#f8f9fa" }}>
        <h1
          className="text-center mb-4 mt-4 fw-bold"
          style={{ color: "#6f00ff" }}>
          Contact us
        </h1>
        <div className="row justify-content-center align-items-center">
          <div className="col-lg-6 col-md-6 col-sm-12">
            <Form onSubmit={handleSubmit}>
              <Form.Group className="mb-3" controlId="formName">
                <Form.Label>Full Name</Form.Label>
                <Form.Control
                  type="text"
                  placeholder="Enter name"
                  name="name"
                  value={formData.name}
                  onChange={handleChange}
                  style={{ backgroundColor: "rgb(235, 235, 235)" }}
                  required
                />
              </Form.Group>
              <Form.Group className="mb-3" controlId="formEmail">
                <Form.Label>Email address</Form.Label>
                <Form.Control
                  type="email"
                  placeholder="Enter email"
                  name="email"
                  value={formData.email}
                  onChange={handleChange}
                  style={{ backgroundColor: "rgb(235, 235, 235)" }}
                  required
                />
              </Form.Group>
              <Form.Label>Select Service</Form.Label>
              <select
                className="form-select mb-3"
                name="service"
                value={formData.service}
                onChange={handleChange}
                style={{ backgroundColor: "rgb(235, 235, 235)" }}
                required>
                <option value="">Select a service</option>
                <option value="UI/UX Design">Website Development</option>
                <option
                  value="Mobile App
                Development">
                  Mobile App Development
                </option>
                <option value="Artificial Intelligence Development">
                  Artificial Intelligence Development
                </option>
                <option value="Blockchain Development">
                  Blockchain Development
                </option>
                <option value="UI/UX Design">UI/UX Design</option>
                <option value="Quality Assurance">Quality Assurance</option>
              </select>
              <Form.Group className="mb-3" controlId="formMessage">
                <Form.Label>Message</Form.Label>
                <Form.Control
                  as="textarea"
                  rows={3}
                  name="message"
                  value={formData.message}
                  onChange={handleChange}
                  style={{ backgroundColor: "rgb(235, 235, 235)" }}
                  required
                />
              </Form.Group>
              <div className="text-center mb-4">
                <Button
                  className="btn"
                  type="submit"
                  style={{ width: "550px", backgroundColor: "#5d21d2" }}>
                  Submit
                </Button>
              </div>
            </Form>
          </div>
        </div>
      </div>
    </>
  );
}

export default ContactUs;
